import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1390 3435 l0 -476 -34 -32 c-21 -20 -39 -50 -46 -76 -44 -166 149
-290 277 -178 63 56 79 126 44 203 -10 22 -30 50 -45 61 l-26 21 0 392 0 392
273 -5 c241 -4 281 -7 347 -26 161 -47 303 -141 398 -262 341 -436 134 -1060
-403 -1214 -48 -14 -116 -18 -337 -22 l-278 -5 0 85 0 85 263 4 c290 6 314 10
442 76 135 71 250 217 289 368 19 75 21 221 2 289 -67 253 -299 445 -538 445
-48 0 -57 3 -75 28 -42 57 -148 81 -212 47 -40 -20 -79 -69 -91 -115 -19 -72
21 -160 91 -195 61 -32 167 -11 208 41 15 19 28 24 63 24 53 0 140 -24 188
-52 50 -30 127 -106 154 -153 109 -186 52 -436 -127 -555 -103 -69 -110 -70
-579 -70 l-418 0 0 386 0 387 34 38 c64 70 68 160 10 229 -36 42 -79 62 -134
62 -58 0 -101 -21 -136 -68 -54 -70 -38 -195 31 -240 l25 -16 0 -479 0 -479
170 0 170 0 0 -171 0 -171 373 4 c420 5 450 9 616 88 115 55 192 113 287 212
347 368 330 955 -40 1305 -123 117 -258 193 -418 235 -76 20 -114 22 -450 25
l-368 5 0 -477z m-213 103 c31 -29 30 -71 -3 -97 -33 -26 -55 -26 -82 -3 -35
29 -38 67 -8 96 31 32 62 33 93 4z m674 -6 c45 -37 16 -122 -41 -122 -31 0
-70 39 -70 70 0 19 9 36 26 49 33 26 55 26 85 3z m-333 -678 c32 -22 30 -76
-5 -103 -63 -51 -141 41 -83 99 24 24 57 26 88 4z"/>
					<path d="M2846 2351 c-14 -26 -66 -142 -117 -258 l-18 -43 39 0 c35 0 39 3 50
35 l12 35 79 0 79 0 17 -35 c15 -31 21 -35 54 -35 l38 0 -24 53 c-13 28 -45
102 -72 162 -49 109 -50 110 -86 113 -31 3 -38 -1 -51 -27z m78 -113 l25 -58
-54 0 c-52 0 -54 1 -46 23 24 60 43 99 46 96 2 -2 15 -30 29 -61z"/>
					<path d="M3130 2350 c0 -29 1 -30 50 -30 l50 0 0 -135 0 -135 40 0 40 0 0 135
0 135 50 0 c49 0 50 1 50 30 l0 30 -140 0 -140 0 0 -30z"/>
					<path d="M3541 2221 c-39 -87 -71 -162 -71 -165 0 -3 16 -6 36 -6 31 0 37 4
50 35 l14 35 79 0 80 0 12 -35 c12 -32 17 -35 52 -35 27 0 38 4 34 13 -3 6
-35 81 -72 165 l-68 152 -37 0 -37 0 -72 -159z m135 24 c13 -31 24 -58 24 -60
0 -3 -22 -5 -50 -5 -27 0 -50 2 -50 4 0 10 43 116 47 116 2 0 15 -25 29 -55z"/>
					<path d="M1110 1759 c-62 -25 -100 -85 -100 -157 0 -88 62 -152 154 -160 66
-5 94 1 130 29 l27 21 -22 24 c-21 22 -24 23 -40 8 -10 -9 -38 -19 -62 -21
-39 -5 -48 -1 -78 25 -29 26 -34 37 -34 77 0 40 5 51 33 76 37 33 76 37 128
13 32 -16 36 -15 54 1 23 21 15 37 -31 61 -32 16 -122 18 -159 3z"/>
					<path d="M1430 1605 l0 -165 35 0 35 0 0 70 0 70 75 0 75 0 0 -70 0 -70 40 0
40 0 0 165 0 165 -40 0 -40 0 0 -65 0 -65 -75 0 -75 0 0 65 0 65 -35 0 -35 0
0 -165z"/>
					<path d="M1860 1605 l0 -165 130 0 130 0 0 30 0 29 -92 3 -93 3 -3 38 -3 37
80 0 81 0 0 30 0 30 -81 0 -80 0 3 33 3 32 88 3 87 3 0 29 0 30 -125 0 -125 0
0 -165z"/>
					<path d="M2261 1611 c-39 -87 -71 -162 -71 -165 0 -3 16 -6 36 -6 31 0 37 4
50 35 l14 35 79 0 80 0 12 -35 c12 -32 17 -35 52 -35 27 0 38 4 34 13 -3 6
-35 81 -72 165 l-68 152 -37 0 -37 0 -72 -159z m135 24 c13 -31 24 -58 24 -60
0 -3 -22 -5 -50 -5 -27 0 -50 2 -50 4 0 10 43 116 47 116 2 0 15 -25 29 -55z"/>
					<path d="M2640 1606 l0 -166 35 0 c35 0 35 0 35 44 l0 43 71 5 c83 6 118 27
136 81 16 48 -1 102 -40 131 -23 17 -46 21 -132 24 l-105 4 0 -166z m188 88
c14 -10 22 -25 22 -44 0 -40 -35 -62 -92 -58 l-43 3 -3 44 c-2 24 -1 50 2 57
7 19 86 18 114 -2z"/>
					<path d="M3040 1605 l0 -165 125 0 125 0 0 30 0 30 -90 0 -90 0 0 40 0 40 80
0 80 0 0 30 0 30 -80 0 -80 0 0 35 0 35 90 0 90 0 0 30 0 30 -125 0 -125 0 0
-165z"/>
					<path d="M3438 1754 c-34 -18 -48 -43 -48 -87 0 -45 24 -65 107 -88 78 -22 92
-32 77 -57 -15 -23 -91 -28 -137 -9 -34 15 -35 14 -46 -10 -6 -13 -9 -28 -6
-33 9 -15 76 -30 133 -30 86 0 132 34 132 98 0 46 -28 70 -103 91 -80 22 -96
34 -81 59 9 13 25 17 65 17 30 -1 61 -4 70 -9 13 -5 19 0 27 23 10 28 9 30
-20 40 -44 16 -138 13 -170 -5z"/>
					<path d="M3720 1740 c0 -30 1 -30 55 -30 l55 0 0 -135 0 -135 35 0 35 0 0 135
0 135 55 0 c54 0 55 0 55 30 l0 30 -145 0 -145 0 0 -30z"/>
					<path d="M1350 1276 c0 -19 -4 -23 -19 -19 -34 9 -61 -14 -61 -52 0 -40 18
-54 73 -52 l37 1 0 73 c0 58 -3 73 -15 73 -9 0 -15 -9 -15 -24z m-2 -75 c-4
-35 -41 -37 -46 -2 -4 26 13 42 35 34 8 -3 13 -16 11 -32z"/>
					<path d="M2130 1225 c0 -59 3 -75 14 -75 10 0 16 13 18 38 2 29 7 37 23 37 16
0 21 -8 23 -37 5 -54 32 -52 32 1 0 47 -23 75 -57 68 -19 -3 -23 0 -23 19 0
15 -6 24 -15 24 -12 0 -15 -15 -15 -75z"/>
					<path d="M1042 1265 c-45 -38 -45 -92 -1 -129 31 -27 72 -33 99 -16 11 7 4 10
-27 10 -52 0 -83 26 -83 70 0 17 9 39 20 50 43 43 135 10 128 -46 -5 -38 -23
-33 -26 7 -3 34 -5 36 -42 37 -30 1 -42 -3 -50 -17 -26 -49 -5 -74 64 -77 46
-2 58 0 66 16 27 50 -10 108 -76 117 -33 4 -45 1 -72 -22z m83 -65 c0 -29 -32
-41 -41 -16 -9 24 4 48 23 44 12 -2 18 -12 18 -28z"/>
					<path d="M1640 1263 c-10 -12 -11 -18 -2 -21 7 -2 12 -21 12 -43 0 -33 4 -40
25 -45 32 -8 46 4 22 17 -20 12 -23 65 -5 71 9 3 8 9 -4 21 -20 21 -29 21 -48
0z"/>
					<path d="M3200 1263 c-10 -12 -11 -18 -2 -21 7 -2 12 -18 12 -36 0 -18 5 -37
12 -44 17 -17 48 -15 48 3 0 8 -6 14 -12 13 -8 -2 -14 10 -16 30 -2 24 1 32
12 32 9 0 16 5 16 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 15 -23 11 -40 -7z"/>
					<path d="M1477 1253 c-13 -12 -7 -25 8 -19 18 6 45 -2 45 -15 0 -5 -9 -9 -19
-9 -32 0 -51 -11 -51 -30 0 -21 18 -28 68 -28 31 -1 32 0 32 43 0 34 -5 47
-19 55 -22 11 -54 13 -64 3z m53 -73 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10
0 6 9 10 20 10 11 0 20 -4 20 -10z"/>
					<path d="M1793 1253 c-23 -9 -14 -25 11 -19 15 4 28 0 36 -9 11 -13 8 -15 -18
-15 -16 0 -35 -7 -42 -15 -20 -25 4 -43 55 -43 44 1 45 2 45 32 0 57 -40 89
-87 69z m52 -73 c-3 -5 -12 -10 -20 -10 -8 0 -17 5 -20 10 -4 6 5 10 20 10 15
0 24 -4 20 -10z"/>
					<path d="M1972 1244 c-53 -37 4 -113 64 -85 22 10 25 14 14 21 -9 6 -19 5 -24
0 -12 -12 -46 6 -46 25 0 18 36 39 45 25 3 -5 13 -6 22 -3 14 6 14 8 -3 20
-24 17 -45 17 -72 -3z"/>
					<path d="M2330 1240 c-25 -25 -25 -45 1 -71 23 -23 70 -23 77 0 3 10 -1 12
-16 9 -12 -3 -29 0 -39 8 -16 12 -13 13 30 14 26 0 47 5 47 10 0 17 -39 50
-60 50 -11 0 -29 -9 -40 -20z m60 -10 c12 -8 9 -10 -13 -10 -16 0 -26 4 -22
10 8 12 16 12 35 0z"/>
					<path d="M2513 1253 c-23 -9 -14 -25 11 -19 15 4 28 0 36 -9 11 -13 8 -15 -18
-15 -16 0 -35 -7 -42 -15 -20 -25 4 -43 55 -43 44 1 45 2 45 32 0 57 -40 89
-87 69z m52 -73 c-3 -5 -12 -10 -20 -10 -8 0 -17 5 -20 10 -4 6 5 10 20 10 15
0 24 -4 20 -10z"/>
					<path d="M2680 1184 c0 -59 3 -74 15 -74 9 0 15 9 15 24 0 19 4 22 23 19 43
-8 73 46 47 87 -5 9 -27 15 -55 16 l-45 2 0 -74z m64 50 c19 -7 21 -50 4 -56
-22 -8 -38 3 -38 26 0 33 8 40 34 30z"/>
					<path d="M2882 1244 c-30 -20 -29 -59 2 -80 28 -18 67 -16 74 5 3 10 -2 12
-21 8 -16 -5 -29 -2 -37 8 -11 13 -7 15 29 15 34 0 41 3 41 20 0 19 -27 40
-52 40 -8 0 -24 -7 -36 -16z m53 -14 c4 -6 -5 -10 -20 -10 -15 0 -24 4 -20 10
3 6 12 10 20 10 8 0 17 -4 20 -10z"/>
					<path d="M3052 1248 c-20 -20 -13 -39 18 -49 40 -14 39 -33 -1 -25 -22 4 -29
2 -27 -7 6 -17 56 -21 74 -6 23 19 16 39 -16 50 -35 13 -40 31 -7 29 35 -3 43
0 27 10 -21 13 -54 12 -68 -2z"/>
					<path d="M3462 1244 c-53 -37 4 -113 64 -85 22 10 25 14 14 21 -8 5 -19 5 -25
0 -8 -6 -18 -5 -30 4 -24 18 -13 48 17 46 47 -4 54 0 34 15 -25 19 -46 19 -74
-1z"/>
					<path d="M3630 1240 c-11 -11 -20 -27 -20 -35 0 -21 36 -55 58 -55 29 0 62 29
62 55 0 26 -33 55 -62 55 -10 0 -27 -9 -38 -20z m70 -34 c0 -23 -22 -36 -42
-25 -23 12 -23 35 -1 48 19 10 43 -2 43 -23z"/>
					<path d="M3800 1204 c0 -41 4 -54 15 -54 10 0 15 10 15 33 0 37 15 58 36 50 9
-3 14 -20 14 -44 0 -28 4 -39 15 -39 10 0 15 10 15 34 0 39 20 62 38 44 7 -7
12 -27 12 -45 0 -23 5 -33 15 -33 21 0 20 73 -1 94 -18 18 -50 21 -59 6 -4 -7
-11 -8 -18 -2 -7 5 -31 10 -54 9 l-43 0 0 -53z"/>
					<path d="M3345 1180 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
